const SPACE_DATA = {
		"u6991701":  [{space_js: "//slkiiw.renbikj.com/source/l-g/resource/fo-o/static/phmp.js", space_src: "baidujs", space_type: "inters", space_id: "u6991701"}],
		"u6991702":  [{space_js: "//slkiiw.renbikj.com/source/m_h_gppq/source/in_w.js", space_src: "baidujs", space_type: "native", space_id: "u6991702"}],
		"u6991703":  [{space_js: "//slkiiw.renbikj.com/common/n_ihq/source/qr/resource/joa.js", space_src: "baidujs", space_type: "native", space_id: "u6991703"}],
		"u6991704":  [{space_js: "//slkiiw.renbikj.com/production/o/production/jir_rsk/common/pf.js", space_src: "baidujs", space_type: "native", space_id: "u6991704"}],
		"u6991705":  [{space_js: "//slkiiw.renbikj.com/production/pk/source/j/openjs/sstl_qh.js", space_src: "baidujs", space_type: "native", space_id: "u6991705"}],
};
export const SPACE_MAP = {
	Home_inter:  SPACE_DATA["u6991701"],
	Home_banner:  SPACE_DATA["u6991702"],
	Main_native:  SPACE_DATA["u6991703"],
	Classroom_native:  SPACE_DATA["u6991704"],
	Wikipedia_cat_native:  SPACE_DATA["u6991703"],
	Wikipedia_dog_native:  SPACE_DATA["u6991704"],
	Wikipedia_other_native:  SPACE_DATA["u6991705"],
	ConstellationList_banner:  SPACE_DATA["u6991702"],
	ConstellationList_native:  SPACE_DATA["u6991703"],
	ArticleDetail_inter:  SPACE_DATA["u6991701"],
	ArticleDetail_banner:  SPACE_DATA["u6991702"],
	ArticleDetail_native:  SPACE_DATA["u6991703"],
	PetDetail_inter:  SPACE_DATA["u6991701"],
	PetDetail_banner:  SPACE_DATA["u6991702"],
	PetDetail_native:  SPACE_DATA["u6991703"],
	Mood_dialog:  SPACE_DATA["u6991705"],
}
export const ALL_SPACE_MAP = {
    H5_RBMC_VO_4 : {
		Home_inter:  SPACE_DATA["u6991701"],
		Home_banner:  SPACE_DATA["u6991702"],
		Main_native:  SPACE_DATA["u6991703"],
		Classroom_native:  SPACE_DATA["u6991704"],
		Wikipedia_cat_native:  SPACE_DATA["u6991703"],
		Wikipedia_dog_native:  SPACE_DATA["u6991704"],
		Wikipedia_other_native:  SPACE_DATA["u6991705"],
		ConstellationList_banner:  SPACE_DATA["u6991702"],
		ConstellationList_native:  SPACE_DATA["u6991703"],
		ArticleDetail_inter:  SPACE_DATA["u6991701"],
		ArticleDetail_banner:  SPACE_DATA["u6991702"],
		ArticleDetail_native:  SPACE_DATA["u6991703"],
		PetDetail_inter:  SPACE_DATA["u6991701"],
		PetDetail_banner:  SPACE_DATA["u6991702"],
		PetDetail_native:  SPACE_DATA["u6991703"],
		Mood_dialog:  SPACE_DATA["u6991705"],
    },
    DEFAULT : {
		Home_inter:  SPACE_DATA["u6991701"],
		Home_banner:  SPACE_DATA["u6991702"],
		Main_native:  SPACE_DATA["u6991703"],
		Classroom_native:  SPACE_DATA["u6991704"],
		Wikipedia_cat_native:  SPACE_DATA["u6991703"],
		Wikipedia_dog_native:  SPACE_DATA["u6991704"],
		Wikipedia_other_native:  SPACE_DATA["u6991705"],
		ConstellationList_banner:  SPACE_DATA["u6991702"],
		ConstellationList_native:  SPACE_DATA["u6991703"],
		ArticleDetail_inter:  SPACE_DATA["u6991701"],
		ArticleDetail_banner:  SPACE_DATA["u6991702"],
		ArticleDetail_native:  SPACE_DATA["u6991703"],
		PetDetail_inter:  SPACE_DATA["u6991701"],
		PetDetail_banner:  SPACE_DATA["u6991702"],
		PetDetail_native:  SPACE_DATA["u6991703"],
		Mood_dialog:  SPACE_DATA["u6991705"],
    },
};
